<template>
  <div class="app-modal__box xl:gap-y-6 gap-y-4">
    <div class="app-modal__header">
      <h3 class="app-title">
        {{ $t('Create Link') }}
      </h3>
    </div>
    <div class="app-modal__body">
      <div class="flex flex-col gap-6">
        <span
          v-if="inviteTo === 'collection'"
          class="text-text-secondary text-pretty"
        >
          {{
            $t(
              'Create a sharable link for your collection. Users following this link will be added as members to your collection and can access all documents.'
            )
          }}
        </span>
        <span
          v-else-if="inviteTo === 'site'"
          class="text-text-secondary text-pretty"
        >
          {{
            $t(
              'Create a sharable link for your site. Users following this link will be added as contributors to your site.'
            )
          }}
        </span>
        <span v-else-if="inviteTo === 'organisation'">
          {{
            $t(
              'Create a sharable link for your organisation. Users following this link will be added as members to your organisation.'
            )
          }}
        </span>
        <div v-if="invitation === null" class="flex flex-col gap-2">
          <BaseSelect
            v-if="inviteTo === 'collection'"
            :name="'role'"
            :id="'role'"
            label="Role"
            :options="
              permissions.myRole?.isOwner ? ownerOptions : editorOptions
            "
            :value="role"
            @selectionChange="role = $event"
          ></BaseSelect>
          <button
            class="align-self-stretch w-full text-primary font-semibold py-8 px-9 rounded-lg bg-gray-50"
            @click="createInviteLink"
            v-if="!isLoading"
          >
            <i class="mdi mdi-link w-6 mr-2"></i>
            {{ $t('Create a shareable link') }}
          </button>
          <div
            v-else
            class="align-self-stretch w-full text-primary font-semibold py-8 px-9 rounded-lg bg-gray-50"
          >
            <div class="app-loading__item m-auto" role="status">
              <span class="app-loading__item__inner">Loading invitation</span>
            </div>
          </div>
        </div>
        <div v-else>
          <BaseInput
            :value="invitationLink"
            disabled
            placeholder="Invitation Link"
          >
          </BaseInput>
          <div class="flex justify-between mt-4">
            <button
              class="text-primary font-semibold flex items-center"
              @click="resetModal"
            >
              <i
                class="mdi mdi-restore w-6 mr-2"
                :class="{ 'animate-rotate': isResetting }"
              ></i>
              {{ $t('Generate new link') }}
            </button>
            <button
              class="text-danger font-semibold flex items-center"
              @click="deleteLink"
            >
              <i class="mdi mdi-delete w-6 mr-2"></i>
              {{ $t('Remove link') }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="app-modal__footer gap-4">
      <BaseButton
        v-if="invitation === null"
        type="secondary"
        label="Cancel"
        @click="close"
      ></BaseButton>
      <BaseButton
        type="primary"
        :label="copyButtonLabel"
        @click="copy"
        :disabled="invitation === null"
      ></BaseButton>
    </div>
  </div>
</template>

<script setup lang="ts">
enum invitationLevel {
  Collection = 'collection',
  Site = 'site',
  Organisation = 'organisation',
}

const props = defineProps({
  inviteTo: {
    type: invitationLevel,
    required: true,
    default: invitationLevel.Collection,
  },
  siteId: {
    type: Number,
    required: false,
  },
})

const { t } = useI18n()
const invitationsApi = useInvitations()
const { $bus } = useNuxtApp()
const route = useRoute()
const permissions = usePermissions()
const isResetting = ref(false) // used for animation
const isLoading = ref(false)

onMounted(async () => {
  if (props.inviteTo === 'collection') {
    await permissions.getCollectionPermissions(parseInt(route.query.collId))
    role.value = permissions.myRole?.isOwner
      ? ownerOptions[0].value
      : editorOptions[0].value
  }
})

const roles = {
  viewer: { name: 'Viewer', value: ROLE_VIEWER },
  manualTranscriber: {
    name: 'Manual Transcriber',
    value: ROLE_MANUAL_TRANSCRIBER,
  },
  transcriber: { name: ROLE_TRANSCRIBER, value: ROLE_TRANSCRIBER },
  editor: { name: ROLE_EDITOR, value: ROLE_EDITOR },
  owner: { name: ROLE_OWNER, value: ROLE_OWNER },
}
const ownerOptions = [
  roles.viewer,
  roles.manualTranscriber,
  roles.transcriber,
  roles.editor,
  roles.owner,
]
const editorOptions = [
  roles.viewer,
  roles.manualTranscriber,
  roles.transcriber,
  roles.editor,
]
const role = ref(roles.viewer.value)
const invitation = ref(null)

const close = () => {
  $bus.emit('close-modal')
}

const resetModal = async () => {
  isResetting.value = true
  setTimeout(() => {
    isResetting.value = false
  }, 1000)
  invitation.value = null
  if (props.inviteTo === 'collection') {
    role.value = permissions.myRole?.isOwner
      ? ownerOptions[0].value
      : editorOptions[0].value
  }
}

const copyButtonLabel = ref('Copy Link')
const copy = async () => {
  try {
    await navigator.clipboard.writeText(invitationLink.value)
    copyButtonLabel.value = 'Link copied'
    setTimeout(() => {
      copyButtonLabel.value = 'Copy Link'
    }, 2000)
  } catch (err) {
    $bus.emit('notification', {
      message: 'Could not copy link to clipboard',
      type: 'error',
    })
  }
}

const deleteLink = async () => {
  if (!invitation.value) return

  try {
    if (props.inviteTo === 'collection') {
      await invitationsApi.deleteCollectionInvitation({
        collId: route.query.collId,
        inviteId: invitation.value.inviteId,
      })
    } else if (props.inviteTo === 'site') {
      await invitationsApi.deleteSitesInvitation({
        sitesId: props.siteId,
        inviteId: invitation.value.inviteId,
      })
    } else if (props.inviteTo === 'organisation') {
      await invitationsApi.deleteSubscriptionInvitation({
        organisationId: route.query.organisationId,
        inviteId: invitation.value.inviteId,
      })
    }
    resetModal()
  } catch (err) {
    $bus.emit('notification', {
      message: t('Could not delete invitation'),
      type: 'error',
    })
  }
}

const invitationLink = computed(() => {
  if (!invitation.value) return 'No invitation generated yet'
  return `${window.location.origin}/invitations/${invitation.value.inviteId}`
})

const createInviteLink = async () => {
  try {
    isLoading.value = true
    if (props.inviteTo === 'collection') {
      invitation.value = await invitationsApi.createAnonymousInvitation({
        collId: route.query.collId,
        message: '',
        role: role.value,
      })
    } else if (props.inviteTo === 'site') {
      invitation.value = await invitationsApi.createAnonymousSiteInvitation({
        sitesId: props.siteId,
        message: '',
      })
    } else if (props.inviteTo === 'organisation') {
      invitation.value = await invitationsApi.createAnonymousOrgaInvitation({
        organisationId: route.query.orgId,
        message: '',
        role: ROLE_ORG_MEMBER,
      })
    }

    $bus.emit('reload-members')
    isLoading.value = false
  } catch (err) {
    $bus.emit('notification', {
      message: t('Could not create invitation'),
      type: 'error',
    })
  }
}
</script>

<style scoped>
.animate-rotate {
  animation: rotate 0.5s;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-270deg);
  }
}
</style>
